import { ReactNode, memo, useEffect, useState } from 'react';
import { ALIGN, HeaderNavigation, StyledNavigationItem, StyledNavigationList } from 'baseui/header-navigation';
import { BeAvatarMenu, BeAvatarMenuProps } from './BeAvatarMenu';
import { BeButtonLink } from '../../forms';
import { LabelLarge } from 'baseui/typography';
import { useRouter } from 'next/router';
import { ChevronDown } from 'baseui/icon';
import { Block } from 'baseui/block';
import Link from 'next/link';
import { BeSideNavItem, BeSideNavItemMenuItem, BeSideNavProps } from './BeSideNav';
import { useBreakpoint, useDebugAttrs } from '../../hooks';
import { BaseWebHelpers, UIHelpers } from '../../helpers';
import { useStyletron, withStyle } from 'baseui';
import dynamic from 'next/dynamic';
import { StyledLoadingSpinner } from 'baseui/button';
import { MarketingUrlMap, RouteEnum, RoutesWithDefaultRedirects } from '@benefeature/shared-common';
import { useSession } from 'next-auth/react';
import { BeSpinnerMessage } from '../../components';
import isEqual from 'lodash-es/isEqual';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

// noinspection RequiredAttributes
const BeNotificationsMenu = dynamic(() => import('./BeNotificationsMenu').then((mod) => mod.BeNotificationsMenu), {
  ssr: false,
  loading: () => <StyledLoadingSpinner />,
});

export type AppHeaderProps = {
  userMenu?: BeAvatarMenuProps;
  entityContextComponent?: ReactNode;
  userFallbackComponent?: ReactNode;
  searchComponent?: ReactNode;
  promoBlock?: ReactNode;
  mainNavMenu?: Omit<BeSideNavProps, 'isCollapsed'>;
  onLogoClicked?: () => void;
  dismissAllNotificationsFn: () => any;
  downloadExportFn: (exportID: string, userID: string, openInNewTab?: boolean) => void;
};

export const BeAppHeaderHeight = 56;

export const ROUTES_WITHOUT_HEADER_SEARCH = [
  RouteEnum.SEARCH,
  RouteEnum.SEARCH_SEARCH_TYPE,
  RouteEnum.AUTH_ACCOUNT_SETUP,
];

export const ROUTES_WITHOUT_HEADER_PROMO_BLOCK = [RouteEnum.AUTH_ACCOUNT_SETUP];

export const ROUTES_WITHOUT_MAIN_NAV = [RouteEnum.AUTH_ACCOUNT_SETUP];

const mainNavMenuCalculateNavItem = (
  navItem: BeSideNavItem,
  $theme: any,
  currentPath: string,
  currentPathname: string
) => {
  let calculatedNavItem = null;

  if (navItem.url || navItem.href) {
    /* Link present in menu */
    if (![navItem.url, navItem.href].includes('/')) {
      /* Check the current path against the nav item's path and mark it as active if there's a match */
      const urlSanitized = UIHelpers.toBasePath(navItem.url);
      const hrefSanitized = UIHelpers.toBasePath(navItem.href);
      const isActive =
        navItem.isActive ||
        /* Only perform a sanitized startsWith check for links that aren't the base "/" path */
        (urlSanitized !== '/' &&
          hrefSanitized !== '/' &&
          ([urlSanitized, hrefSanitized].some((val) => currentPath?.startsWith(val)) ||
            [urlSanitized, hrefSanitized].some((val) => currentPathname?.startsWith(val))));
      // noinspection RequiredAttributes
      calculatedNavItem = (
        <StyledNavigationItem style={{ textAlign: 'center' }} key={`main-nav-${navItem.label}`}>
          <Link
            href={navItem.url || navItem.href || ''}
            style={{
              cursor: 'pointer',
              color: isActive ? $theme.colors.contentAccent : $theme.colors.contentSecondary,
            }}
          >
            <LabelLarge
              color={isActive ? $theme.colors.contentAccent : $theme.colors.contentSecondary}
              display={'flex'}
              alignItems={'center'}
              gridColumnGap={'4px'}
              overrides={{
                Block: {
                  style: {
                    userSelect: 'none',
                    ...(isActive ? { fontWeight: '600' } : {}),
                  },
                },
              }}
            >
              {navItem.iconClass ? (
                <navItem.iconClass
                  style={{
                    fontSize: '1.1em',
                    color: isActive ? $theme.colors.contentAccent : $theme.colors.contentSecondary,
                  }}
                />
              ) : null}
              {navItem.label}
            </LabelLarge>
          </Link>
        </StyledNavigationItem>
      );
    }
  } else if (navItem.label) {
    // No link but has a label
    // Can be used for menu handling as well
    // Suppress ConditionalExpressionWithIdenticalBranchesJS, inspection is broken for the menuItem style blocks
    // noinspection RequiredAttributes,ConditionalExpressionWithIdenticalBranchesJS
    const calculatedLabel = (
      <LabelLarge
        color={$theme.colors.contentSecondary}
        display={'flex'}
        alignItems={'center'}
        gridColumnGap={'4px'}
        overrides={{
          Block: {
            style: {
              textDecoration: 'none',
              userSelect: 'none',

              // If there are menuItems, add a rounded border to simulate button appearance
              // eslint-disable-next-line
              ...(navItem.menuItems == null
                ? {}
                : {
                    cursor: 'pointer',
                    ...BaseWebHelpers.expandBorderStyles({
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: $theme.colors.borderOpaque,
                    }),
                    ...BaseWebHelpers.padding('4px'),
                    ...BaseWebHelpers.borderRadius('4px'),
                  }),
            },
          },
        }}
      >
        {navItem.iconClass ? (
          <navItem.iconClass
            style={{
              fontSize: '1.1em',
              /* No calculation for isActive possible without a link */
              color: $theme.colors.contentTertiary,
            }}
          />
        ) : null}

        <Block {...(navItem?.menuItems ? { paddingLeft: '2px' } : {})}>
          {navItem.label}

          {/* eslint-disable-next-line eqeqeq */}
          {navItem?.menuItems == null ? null : (
            <Block
              overrides={{
                Block: { style: { fontSize: '0.6em', lineHeight: '0.6em', paddingBottom: '4px' } },
              }}
            >
              Select a dashboard
            </Block>
          )}
        </Block>

        {/* eslint-disable-next-line eqeqeq */}
        {navItem?.menuItems == null ? null : (
          <ChevronDown
            size={28}
            title={'Select a dashboard'}
            /* Padding to align with style of BeAvatarMenu chevron */
            overrides={{ Svg: { style: ({ $theme }: any) => ({ paddingLeft: $theme.sizing.scale200 }) } }}
          />
        )}
      </LabelLarge>
    );

    // Optionally inject a popover for the menu, if it's defined
    calculatedNavItem = (
      <StyledNavigationItem style={{ textAlign: 'center' }} key={`main-nav-${navItem.label}`}>
        {navItem?.menuItems && navItem.menuItems.length > 0 ? (
          <StatefulPopover
            returnFocus
            dismissOnClickOutside={true}
            placement={PLACEMENT.bottomLeft}
            popperOptions={{ modifiers: { flip: { enabled: false } } }}
            triggerType={TRIGGER_TYPE.click}
            overrides={{
              Body: {
                style: {
                  marginTop: '2px',
                },
              },
              Inner: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.white,
                  width: innerWidth,
                  ...BaseWebHelpers.borderRadius('8px'),
                }),
              },
            }}
            content={({ close }) => (
              <Block
                overrides={{
                  Block: {
                    style: {
                      ...BaseWebHelpers.borderRadius('8px'),
                    },
                  },
                }}
              >
                <StatefulMenu
                  items={navItem.menuItems as any[]}
                  onItemSelect={({ item }: { item: BeSideNavItemMenuItem }) => {
                    if (item.onClick) {
                      item.onClick();
                    }
                    close();
                  }}
                  overrides={{
                    List: {
                      style: {
                        ...BaseWebHelpers.borderRadius('8px'),
                        ':focus': {
                          outline: 'none',
                        },
                      },
                    },
                    ListItem: {
                      style: ({ $theme }) => ({
                        paddingLeft: '22px',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: $theme.colors.contentPrimary,
                        lineHeight: '40px',
                      }),
                    },
                  }}
                />
              </Block>
            )}
          >
            {calculatedLabel}
          </StatefulPopover>
        ) : (
          calculatedLabel
        )}
      </StyledNavigationItem>
    );
  }

  return calculatedNavItem;
};

// Generally requires a dynamic import to prevent hydration errors
export const BeAppHeader = memo(
  function BeAppHeader({
    userMenu,
    searchComponent,
    promoBlock,
    entityContextComponent,
    userFallbackComponent,
    mainNavMenu,
    onLogoClicked,
    dismissAllNotificationsFn,
    downloadExportFn,
  }: AppHeaderProps) {
    const [section] = useDebugAttrs('BeAppHeader');
    const [, /*css*/ $theme] = useStyletron();
    const router = useRouter();

    const { data: session, status: sessionStatus } = useSession();

    // Breakpoint-derived state
    // Required to prevent hydration issues
    const breakpoint = useBreakpoint();
    const [smallView, setSmallView] = useState(['default', 'small'].includes(breakpoint));
    const [shrinkElements, setShrinkElements] = useState(breakpoint !== 'xlarge');
    useEffect(() => {
      setSmallView(['default', 'small'].includes(breakpoint));
      setShrinkElements(breakpoint !== 'xlarge');
    }, [breakpoint]);

    const [showMainNavItems, setShowMainNavItems] = useState(true);
    const [showPromoBlock, setShowPromoBlock] = useState(true);
    const [showSearchBox, setShowSearchBox] = useState(true);
    useEffect(() => {
      setShowMainNavItems(!ROUTES_WITHOUT_MAIN_NAV.includes(router.route as RouteEnum));
      setShowSearchBox(!ROUTES_WITHOUT_HEADER_SEARCH.includes(router.route as RouteEnum));
      setShowPromoBlock(!ROUTES_WITHOUT_HEADER_PROMO_BLOCK.includes(router.route as RouteEnum));
    }, [router.route]);

    return (
      <HeaderNavigation
        {...section('root')}
        overrides={{
          Root: {
            style: () => ({
              height: `${BeAppHeaderHeight}px`,
              ...BaseWebHelpers.padding('0'),
              backgroundColor: '#fff',
              /* Prevent x-axis overflows */
              maxWidth: '100vw',
            }),
          },
        }}
      >
        <StyledNavigationList $align={ALIGN.left}>
          <TopMenuNavigationLogoItem>
            <BeButtonLink
              {...(shrinkElements && onLogoClicked ? { onClick: onLogoClicked } : {})}
              {...(shrinkElements ? {} : { href: session?.user ? RouteEnum.HOME : MarketingUrlMap.HOME })}
              startEnhancer={
                /* Display either icon-only or full logo in start enhancer based on breakpoint */
                <>
                  <Block display={['block', 'block', 'block', 'none', 'none']}>
                    <img
                      style={{ verticalAlign: 'middle' }}
                      alt={'Benefeature'}
                      src={
                        /* Can't use ikImg here */ `${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/benefeature/logo-icon.svg`
                      }
                    />
                  </Block>
                  <Block display={['none', 'none', 'none', 'block', 'block']}>
                    <img
                      style={{ verticalAlign: 'middle' }}
                      alt={'Benefeature'}
                      src={`${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/benefeature/logo-black.svg`}
                    />
                  </Block>
                </>
              }
              enhancerOnly={!shrinkElements}
              kind={'tertiary'}
              overrides={{
                BaseButton: {
                  props: {
                    title: shrinkElements ? 'Open navigation menu' : 'Home',
                  },
                  style: {
                    ...BaseWebHelpers.padding('4px'),
                    borderTopColor: 'rgba(255, 255, 255, 0)',
                    borderBottomColor: 'rgba(255, 255, 255, 0)',
                    borderLeftColor: 'rgba(255, 255, 255, 0)',
                    borderRightColor: 'rgba(255, 255, 255, 0)',
                  },
                },
                StartEnhancer: {
                  style: {
                    marginRight: 0,
                  },
                },
              }}
            >
              {shrinkElements && (
                <ChevronDown
                  size={28}
                  title={'Open navigation menu'}
                  /* Padding to align with style of BeAvatarMenu chevron */
                  overrides={{ Svg: { style: ({ $theme }: any) => ({ paddingLeft: $theme.sizing.scale200 }) } }}
                />
              )}
            </BeButtonLink>
          </TopMenuNavigationLogoItem>
        </StyledNavigationList>

        {mainNavMenu?.items && showMainNavItems && (
          <TopMenuNavigationList $align={ALIGN.left}>
            {mainNavMenu?.items?.reduce((res: any[], navItem) => {
              res.push(
                mainNavMenuCalculateNavItem(
                  navItem,
                  $theme,
                  UIHelpers.toBasePath(router.asPath),
                  UIHelpers.toBasePath(router.pathname)
                )
              );
              return res;
            }, [])}
          </TopMenuNavigationList>
        )}

        {showPromoBlock && promoBlock ? (
          <StyledNavigationList
            $align={ALIGN.center}
            style={{
              paddingLeft: '6px',
              paddingRight: '6px',
              flexShrink: 1 /* Allow shrinking of search input vs other header elements */,
            }}
          >
            <StyledNavigationItem
              style={{
                ...BaseWebHelpers.padding('0'),
              }}
            >
              {promoBlock}
            </StyledNavigationItem>
          </StyledNavigationList>
        ) : null}

        {showSearchBox && searchComponent && (
          <StyledNavigationList
            $align={ALIGN.right}
            style={{
              paddingLeft: '6px',
              paddingRight: '6px',
              marginLeft: 'auto' /* Pull box to right without an ALIGN.center element before it */,
              flexShrink: 1 /* Allow shrinking of search input vs other header elements */,
            }}
          >
            <StyledNavigationItem
              style={{
                ...BaseWebHelpers.padding('0'),
                maxWidth: '450px',
                marginLeft: shrinkElements ? '0' : '16px',
                marginRight: shrinkElements && !session?.user ? '24px' : null,
              }}
            >
              {searchComponent}
            </StyledNavigationItem>
          </StyledNavigationList>
        )}

        {!shrinkElements || (userMenu && session?.user) ? (
          <StyledNavigationList
            $align={ALIGN.right}
            style={{
              ...(showSearchBox && searchComponent ? {} : { marginLeft: 'auto' /* Pull to right */ }),
            }}
          >
            {/* Notifications menu - only display if there's an authenticated user */}
            {userMenu && session?.user && (
              <TopMenuNotificationItem>
                <BeNotificationsMenu
                  dismissAllNotificationsFn={dismissAllNotificationsFn}
                  downloadExportFn={downloadExportFn}
                />
              </TopMenuNotificationItem>
            )}

            {/* Context menu */}
            {!smallView && entityContextComponent && sessionStatus !== 'loading' ? (
              <TopMenuContextItem>{entityContextComponent}</TopMenuContextItem>
            ) : null}

            {/* User menu */}
            <TopMenuUserItem
              style={{
                /* Only set padding left to zero if there's a user menu */
                ...(userMenu && session?.user ? { paddingLeft: '0px' } : {}),
              }}
            >
              {sessionStatus === 'loading' ? (
                <BeSpinnerMessage
                  noMarginBottom
                  containerOverrides={{ Block: { style: { minWidth: '32px', minHeight: '32px', fontSize: '32px' } } }}
                />
              ) : userMenu && session?.user ? (
                <BeAvatarMenu {...userMenu} avatarSize={'32px'} />
              ) : (
                !shrinkElements && (
                  <Block>{userFallbackComponent ? userFallbackComponent : <UnauthenticatedUserMenuFallback />}</Block>
                )
              )}
            </TopMenuUserItem>
          </StyledNavigationList>
        ) : null}
      </HeaderNavigation>
    );
  },
  (a, b) => isEqual(a, b)
);

const TopMenuNavigationList = withStyle(StyledNavigationList, ({ $theme }) => ({
  /* Don't display unless at least the xlarge breakpoint */
  display: 'none',
  [$theme.mediaQuery['xlarge']]: {
    display: 'flex',
  },
}));

const TopMenuNavigationLogoItem = withStyle(StyledNavigationItem, ({ $theme }) => ({
  /* Shrink left padding if on a small display */
  [$theme.mediaQuery.small]: {
    paddingLeft: '12px',
  },
}));

const TopMenuUserItem = withStyle(StyledNavigationItem, ({ $theme }) => ({
  /* Shrink padding if on a small display */
  paddingRight: '26px',
  paddingLeft: '12px',
  [$theme.mediaQuery.small]: {
    paddingRight: '12px',
  },
}));

const TopMenuContextItem = withStyle(StyledNavigationItem, () => ({
  paddingRight: '8px',
  paddingLeft: '0px',
  maxHeight: '48px',
}));

const TopMenuNotificationItem = withStyle(StyledNavigationItem, () => ({
  paddingRight: '16px',
  paddingLeft: '16px',
  maxHeight: '48px',
}));

const UnauthenticatedUserMenuFallback = () => {
  const router = useRouter();
  const [, /*css*/ $theme] = useStyletron();

  // Establish whether to set a login redirect back to the current page or leave that empty
  const redirectBackOnLogin = !RoutesWithDefaultRedirects.some((route) => {
    return router.pathname === route;
  });

  return [RouteEnum.AUTH_SIGNUP, RouteEnum.AUTH_LOGIN].includes(router.pathname as RouteEnum) ? null : (
    <Block display={'flex'} alignItems={'center'} gridColumnGap={'20px'} marginRight={'24px'}>
      <BeButtonLink
        size={'default'}
        href={RouteEnum.AUTH_SIGNUP + (redirectBackOnLogin ? `?redirect=${encodeURIComponent(router.asPath)}` : '')}
        kind={'primary'}
      >
        Sign up
      </BeButtonLink>

      <BeButtonLink
        size={'default'}
        href={RouteEnum.AUTH_LOGIN + (redirectBackOnLogin ? `?redirect=${encodeURIComponent(router.asPath)}` : '')}
        kind={'secondary'}
        colors={{ backgroundColor: $theme.colors['yellow700'], color: $theme.colors.contentInversePrimary }}
      >
        Log in
      </BeButtonLink>
    </Block>
  );
};
