/* Import individual lodash dependencies, much smaller bundle size that way */
import forOwn from 'lodash-es/forOwn';
import isUndefined from 'lodash-es/isUndefined';
import isNull from 'lodash-es/isNull';
import isNaN from 'lodash-es/isNaN';
import isString from 'lodash-es/isString';
import isEmpty from 'lodash-es/isEmpty';
import isObject from 'lodash-es/isObject';
import isArray from 'lodash-es/isArray';
import pull from 'lodash-es/pull';
import cloneDeep from 'lodash-es/cloneDeep';
import reactStringReplace from 'react-string-replace';

import { NextRouter } from 'next/router';
import { ChangeAction, Option, Value } from 'baseui/select/types';
import { ReactNode } from 'react';
import { Prisma } from '@prisma/client';
import qs from 'qs';
import toNumber from 'lodash-es/toNumber';

// Calculate the transformation of the input value to map into the target range based on the min and max values found in the set
// Derived from https://stats.stackexchange.com/a/178629/372020
export const normalizeToRange = (
  input: number,
  targetRangeMin: number,
  targetRangeMax: number,
  inputMin: number,
  inputMax: number
) => {
  // Start by multiplying all input values by some large number to prevent fractional multiplications later
  // Complex arithmetic is required for variable ranges and inputs
  // todo explore default behavior for input min equaling max
  // prettier-ignore
  // noinspection OverlyComplexArithmeticExpressionJS
  return inputMin === inputMax
         ? 1
         : (targetRangeMax - targetRangeMin) * ((input - inputMin) / (inputMax - inputMin)) + targetRangeMin;
};

// Calculate a random number between the min and max
export const getRandomArbitrary = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const hasJsonStructure = (candidate) => {
  if (typeof candidate !== 'string') {
    return false;
  }
  try {
    const result = JSON.parse(candidate);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
};

export const median = (numbers: number[]): number => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

// Recursively unnest an object and convert it into a titlecased, textual representation
export const unnestObjectToString = (obj): string => {
  if (!obj) {
    // Return an empty string if no object was provided
    return '';
  }

  if (typeof obj === 'string') {
    // Return the string
    return obj;
  }

  if (Object.prototype.toString.call(obj) === '[object Object]') {
    // For each value, call this function
    return Object.values(obj)
      .map((entry) => unnestObjectToString(entry))
      .join(', ');
  }

  if (Array.isArray(obj)) {
    // Recurse and join each by a comma
    return obj.map((entry) => unnestObjectToString(entry)).join(', ');
  }
};

export const hasJsonArray = (candidate) => {
  if (typeof candidate !== 'string') {
    return false;
  }
  try {
    const result = JSON.parse(candidate);
    const type = Object.prototype.toString.call(result);
    return type === '[object Array]';
  } catch (err) {
    return false;
  }
};

export const flattenObject = (obj, roots = [], sep = '-') =>
  Object.keys(obj)
    // Return an object by iterating props
    .reduce(
      (memo, prop) =>
        Object.assign(
          // Create a new object
          {},
          // Include previously returned object
          memo,
          Object.prototype.toString.call(obj[prop]) === '[object Object]'
            ? // Keep working if value is an object
              flattenObject(obj[prop], roots.concat([prop]), sep)
            : // Include current prop and value and prefix prop with the roots
              { [roots.concat([prop]).join(sep)]: obj[prop] }
        ),
      {}
    );

export function pruneEmpty(obj) {
  // Recursively removes all keys which have values which are undefined, null, NaN, or are an empty string or empty object
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isString(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // Remove any leftover undefined values from the delete operation on an array
    if (isArray(current)) {
      pull(current, undefined);
    }

    return current;
  })(cloneDeep(obj)); // Do not modify the original object, create a clone instead
}

export function chunkArray(arr, chunkSize): any[] {
  // Creates an array of chunks of the provided size from the input
  return Array.from({ length: Math.ceil(arr.length / chunkSize) }, (_, index) =>
    arr.slice(index * chunkSize, (index + 1) * chunkSize)
  );
}

export function mergeObjectArrays(arrayOne, arrayTwo, idFieldArrayOne, idFieldArrayTwo, manyToOneField?) {
  if (manyToOneField) {
    return arrayOne.map((array1Item) => ({
      ...array1Item,
      [manyToOneField]: arrayTwo.find(
        (array2Item) => array2Item[idFieldArrayTwo] === array1Item[idFieldArrayOne] && array2Item
      ),
    }));
  } else {
    return arrayOne.map((array1Item) => ({
      ...arrayTwo.find((array2Item) => array2Item[idFieldArrayTwo] === array1Item[idFieldArrayOne] && array2Item),
      ...array1Item,
    }));
  }
}

export function objToQueryParams(obj: Record<string, any>): string {
  return Object.entries(obj)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

export function debounce(f: (unknown) => unknown, ms = 250, immediate?: boolean) {
  let timeout;
  return function executedFunction() {
    // Must alias `this` for reflection to work on function call
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    // Just use arguments, much simpler than the alternative spreads
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) {
        f.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, ms);
    if (callNow) {
      f.apply(context, args);
    }
  };
}

export function numberWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function mode(array) {
  if (array.length === 0) {
    return null;
  }
  const modeMap = {};
  let maxEl = array[0],
    maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (modeMap[el] == null) {
      modeMap[el] = 1;
    } else {
      modeMap[el]++;
    }
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

export function groupBy(objArray, key) {
  return objArray.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).unshift(x);
    return rv;
  }, {});
}

// May have undefined behavior since objects are read in a consistent manner most of the time
export function sortObjectByKeys(objToSort, order: 'asc' | 'desc' = 'asc') {
  const asc = (a, b) => a - b;
  const desc = (a, b) => b - a;
  const sortMethod = order === 'asc' ? asc : desc;
  return Object.keys(objToSort)
    .sort(sortMethod)
    .reduce((obj, key) => {
      obj[key] = objToSort[key];
      return obj;
    }, {});
}

export function removeKeysFromObject(objToStrip: any, keysToRemove: string[]): any {
  return Object.keys(objToStrip).reduce((obj, key) => {
    if (!keysToRemove.includes(key)) {
      obj[key] = objToStrip[key];
    }
    return obj;
  }, {});
}

export function queryParamToStringArray(param: string | string[]) {
  return param ? (typeof param === 'string' ? [param] : param) : [];
}

export function resolvePromiseAllObject<T extends Record<keyof T, any>>(
  obj: T
): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
  return Promise.all(
    Object.entries(obj).map(async ([k, v]) => {
      // Await the value, or recurse if the value isn't a function or thenable
      return [k, await (typeof v === 'function' || typeof v['then'] !== 'undefined' ? v : resolvePromiseAllObject(v))];
    })
  ).then(Object.fromEntries);
}

export function recursiveLogPrismaSql(obj: Record<string, any>, logLevel = 'error', prefix = ''): void {
  Object.entries(obj).forEach(([key, val]) => {
    if (Object.prototype.hasOwnProperty.call(val, 'strings')) {
      // Log
      logPrismaSql(val, key, logLevel);
    } else {
      // Recurse
      recursiveLogPrismaSql(val, logLevel, prefix ? `${prefix}.${key}` : key);
    }
  });
}

export function logPrismaSql(query: Prisma.Sql | any, key?: string, logLevel = 'error'): void {
  if (query && query.strings) {
    console[logLevel]('Query', key, ':', query.strings.join('')?.replaceAll('\\n', ' '));
    console[logLevel]('Query', key, 'params:', query.values);
  }
}

export type HandleableQueryChangeParamOption = Option | string | string[] | undefined | null;

export type HandleableQueryChangeParams = /* Derived from OnChangeParams from BaseUI */ {
  value?: Value;
  option: HandleableQueryChangeParamOption;
  type: ChangeAction | 'replace';
};

export function removeOptionFromParams(queryParam: string[], optionToRemove: HandleableQueryChangeParamOption) {
  return queryParam.filter((val) => {
    if (Array.isArray(optionToRemove)) {
      // Handle string array type, prepend all
      return !optionToRemove.includes(val);
    } else if (typeof optionToRemove === 'string') {
      // Handle string type, prepend
      return val !== optionToRemove;
    } else {
      // Handle option types, assign based on the ID value
      return val !== optionToRemove.id.toString();
    }
  });
}

/**
 * Update the defined query param per a set of change parameters and push.
 * Note that this will add a history step on EVERY call - no shallow routing per NextJS 13+ unfortunately.
 * This means that consecutive calls, even in the same tick, will be included in history.
 *
 * @param router
 * @param queryParam
 * @param params
 * @param resetPageNumber
 */
export function handleQueryParamUpdate(
  router: NextRouter,
  queryParam: string,
  params: HandleableQueryChangeParams,
  resetPageNumber = true
) {
  if (resetPageNumber && toNumber(router.query.page) > 1) {
    router.query.page = '1';
  }

  switch (params.type) {
    case 'replace':
      // Replace the existing filters in the router query with the provided values and push

      if (Array.isArray(params.option) || typeof params.option === 'string') {
        // Handle string and string array types, just assign directly
        router.query[queryParam] = params.option;
      } else {
        // Handle option types, assign based on the ID value
        router.query[queryParam] = params.option.id.toString();
      }

      router.push({ pathname: router.pathname, query: router.query }, null);
      break;

    case 'select':
      // Add the filter option to the router query and push
      if (Array.isArray(params.option)) {
        // Handle string array type, prepend all
        router.query[queryParam] = [...params.option, ...queryParamToStringArray(router.query[queryParam])];
      } else if (typeof params.option === 'string') {
        // Handle string type, prepend
        router.query[queryParam] = [params.option, ...queryParamToStringArray(router.query[queryParam])];
      } else {
        // Handle option types, assign based on the ID value
        router.query[queryParam] = [params.option.id.toString(), ...queryParamToStringArray(router.query[queryParam])];
      }
      router.push({ pathname: router.pathname, query: router.query }, null);
      break;

    case 'remove':
      // Remove the filter option from the router query and push
      router.query[queryParam] = removeOptionFromParams(
        queryParamToStringArray(router.query[queryParam]),
        params.option
      );
      router.push({ pathname: router.pathname, query: router.query }, null);
      break;

    case 'clear':
      // Clear all filter options in the router query and push
      delete router.query[queryParam];
      router.push({ pathname: router.pathname, query: router.query }, null);
      break;
  }
}

export function buildEmployerProfileQuery(
  selectedEINs: string | string[] | undefined,
  selectedProducts: string | string[] | undefined,
  includeIncompleteYears: string | string[] | undefined,
  selectedCarriers: string | string[] | undefined,
  selectedBrokers: string | string[] | undefined
) {
  selectedEINs = selectedEINs == null ? null : typeof selectedEINs === 'string' ? [selectedEINs] : selectedEINs;
  selectedProducts =
    selectedProducts == null ? null : typeof selectedProducts === 'string' ? [selectedProducts] : selectedProducts;
  includeIncompleteYears =
    includeIncompleteYears == null
      ? null
      : typeof includeIncompleteYears === 'string'
      ? [includeIncompleteYears]
      : includeIncompleteYears;
  selectedCarriers =
    selectedCarriers == null ? null : typeof selectedCarriers === 'string' ? [selectedCarriers] : selectedCarriers;
  selectedBrokers =
    selectedBrokers == null ? null : typeof selectedBrokers === 'string' ? [selectedBrokers] : selectedBrokers;
  const hasSelectedEINs = selectedEINs != null && selectedEINs.length;
  const hasSelectedProducts = selectedProducts != null && selectedProducts.length;
  const hasIncludeIncompleteYears = includeIncompleteYears != null && includeIncompleteYears.length;
  const hasSelectedCarriers = selectedCarriers != null && selectedCarriers.length;
  const hasSelectedBrokers = selectedBrokers != null && selectedBrokers.length;

  let queryString = '';
  if (
    hasSelectedEINs ||
    hasSelectedProducts ||
    hasIncludeIncompleteYears ||
    hasSelectedCarriers ||
    hasSelectedBrokers
  ) {
    queryString += '?';

    // EINs
    if (hasSelectedEINs) {
      queryString += selectedEINs.map((ein) => `selected_eins=${encodeURIComponent(ein)}`).join('&');
    }

    // Products
    if (hasSelectedProducts) {
      if (!queryString.endsWith('?')) {
        queryString += '&';
      }
      queryString += selectedProducts.map((product) => `selected_products=${encodeURIComponent(product)}`).join('&');
    }

    // Show incomplete insurance
    if (hasIncludeIncompleteYears) {
      if (!queryString.endsWith('?')) {
        queryString += '&';
      }

      // Treat includeIncompleteYears as a potential array even though it should only exist once with a value of "true"
      queryString += includeIncompleteYears
        .map((includeVal) => `include_incomplete_years=${encodeURIComponent(includeVal)}`)
        .join('&');
    }

    // Carriers
    if (hasSelectedCarriers) {
      if (!queryString.endsWith('?')) {
        queryString += '&';
      }
      queryString += selectedCarriers.map((carrier) => `selected_carriers=${encodeURIComponent(carrier)}`).join('&');
    }

    // Brokers
    if (hasSelectedBrokers) {
      if (!queryString.endsWith('?')) {
        queryString += '&';
      }
      queryString += selectedBrokers.map((broker) => `selected_brokers=${encodeURIComponent(broker)}`).join('&');
    }
  }
  return queryString;
}

export function buildQueryParams(
  query: Record<string, any>,
  params: string[],
  additionalParams: Record<string, string> = {}
) {
  return qs.stringify(
    {
      ...params.reduce((current, key) => {
        if (query[key]) {
          current[key] = query[key];
        }
        return current;
      }, {}),

      // Append additional params as provided
      ...additionalParams,
    },
    { arrayFormat: 'repeat' }
  );
}

export const markHighlights = (entity: any, targetAttribute: string, highlightObjectAttribute: string): ReactNode[] => {
  if (entity?.[highlightObjectAttribute]?.[targetAttribute]) {
    // Return a replaced variant of the highlight attribute object, split into strings and wrapped elements
    return reactStringReplace(
      entity[highlightObjectAttribute][targetAttribute],
      /{{HIGHLIGHTED}}(.*?)?{{HIGHLIGHTED_END}}/gs,
      (match, i) => <mark key={i}>{match}</mark>
    ) as ReactNode[];
  } else {
    // If the attribute isn't in the highlight object, just return the variation on the base entity as available
    return entity?.[targetAttribute];
  }
};

// Function to recursively check whether there are any keys with populated values
// Used to check if any meaningful content is present
export const hasAnyValues = (obj: any) => {
  return (
    obj &&
    Object.values(obj)?.some((v) =>
      v && typeof v === 'object' ? hasAnyValues(v) : Array.isArray(v) ? v?.some((x) => hasAnyValues(x)) : v != null
    )
  );
};

export const isValidEmailFormat = (email: string) => {
  return email?.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const wait = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isAlphaNumeric = (str: string) => {
  let code: number, i: number, len: number;
  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) // lower alpha (a-z)
    ) {
      return false;
    }
  }
  return true;
};

export const isAlpha = (str: string) => {
  let code: number, i: number, len: number;
  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) // lower alpha (a-z)
    ) {
      return false;
    }
  }
  return true;
};

export const getScrollParent = (element, includeHidden) => {
  if (!document) {
    return null;
  }

  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === 'fixed') {
    return document.body;
  }

  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      // noinspection ContinueStatementJS
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent;
    }
  }

  return document.body;
};
