import { ReactNode, forwardRef } from 'react';
import { ARTWORK_SIZES, ListItem, ListItemLabel, MenuAdapterProps } from 'baseui/list';
import { StatefulMenu } from 'baseui/menu';
import { useRouter } from 'next/router';
import Link from 'next/link';

export type BeSideNavItemMenuItem = {
  label: string | ReactNode;
  [name: string]: any;
  onClick?: () => void;
};

export interface BeSideNavItem {
  icon?: (any?) => string | ReactNode;
  iconClass?: any;
  label: string;
  description?: string;
  url?: string;
  href?: string;

  menuItems?: BeSideNavItemMenuItem[];

  [name: string]: any;
}

export type BeSideNavProps = {
  isCollapsed?: boolean;
  onItemClick?: (item: any) => void;
  items: BeSideNavItem[];
};

export const BeSideNav = ({ items, onItemClick, isCollapsed }: BeSideNavProps) => {
  return (
    <>
      <StatefulMenu
        items={items}
        onItemSelect={({ item }) => (onItemClick == null ? null : onItemClick(item))}
        overrides={{
          List: {
            style: {
              height: '100%',
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: '20px',
              ':focus': {
                outline: 'none',
              },
            },
          },
          Option: {
            props: {
              overrides: {
                ListItem: {
                  component: forwardRef((props: any, ref) => {
                    return props.item.url || props.item.href ? (
                      <Link href={props.item.url || props.item.href}>
                        <MenuAdapter
                          {...props}
                          ref={ref}
                          artwork={props.item.icon}
                          artworkSize={ARTWORK_SIZES.LARGE}
                          iconOnly={isCollapsed}
                        >
                          {isCollapsed ? null : (
                            <ListItemLabel
                              description={props.item.description || ''}
                              overrides={{
                                LabelDescription: {
                                  style: ({ $theme }: any) => ({
                                    color: $theme.colors.gray600,
                                  }),
                                },
                              }}
                            >
                              {props.item.label}
                            </ListItemLabel>
                          )}
                        </MenuAdapter>
                      </Link>
                    ) : (
                      <MenuAdapter
                        {...props}
                        ref={ref}
                        artwork={props.item.icon}
                        artworkSize={ARTWORK_SIZES.LARGE}
                        iconOnly={isCollapsed}
                      >
                        {!isCollapsed && (
                          <ListItemLabel
                            description={props.item.description}
                            overrides={{
                              LabelDescription: {
                                style: ({ $theme }: any) => ({
                                  color: $theme.colors.gray600,
                                }),
                              },
                            }}
                          >
                            {props.item.label}
                          </ListItemLabel>
                        )}
                      </MenuAdapter>
                    );
                  }),
                },
              },
            },
          },
        }}
      />
    </>
  );
};

const MenuAdapter = forwardRef<MenuAdapterProps, HTMLLIElement>(({ iconOnly, ...props }: any, ref: any) => {
  const router = useRouter();
  return (
    <ListItem
      ref={ref}
      sublist={props.sublist || props.$size === 'compact'}
      artwork={props.artwork}
      artworkSize={props.artworkSize}
      overrides={{
        ArtworkContainer: {
          style: ({ $theme }: any) => ({
            color: [
              props.item.href,
              typeof props.item.url === 'string' ? props.item.url?.split('?')?.[0] : '',
            ].includes(router.route)
              ? $theme.colors.yellow900
              : $theme.colors.blue900,
            width: iconOnly ? '100%' : '64px',
            paddingLeft: iconOnly ? 0 : props.item.label ? '8px' : '6px',
          }),
        },
        Root: {
          props: { onMouseEnter: props.onMouseEnter, onClick: props.onClick },
          style: ({ $theme }: any) => ({
            height: '62px',
            backgroundColor: props.$isHighlighted ? $theme.colors.menuFillHover : null,
            cursor: props.$disabled ? 'not-allowed' : 'pointer',
          }),
        },
        Content: {
          style: {
            height: '62px',
            ...(iconOnly
              ? {
                  width: 0,
                  maxWidth: 0,
                  paddingRight: 0,
                  borderBottomSize: 0,
                }
              : {}),
          },
        },
      }}
    >
      {props.children}
    </ListItem>
  );
});
