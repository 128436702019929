import React, { ReactNode, memo, useEffect, useState, useCallback } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { AppHeaderProps, BeAppFooter, BeSideNav } from './components';
import { useBreakpoint, useDebugAttrs } from '../hooks';
import { ANCHOR, Drawer } from 'baseui/drawer';
import { useRouter } from 'next/router';
import { Layer } from 'baseui/layer';
import { RouteEnum } from '@benefeature/shared-common';
import { StatefulMenu } from 'baseui/menu';
import { Navigation } from 'baseui/side-navigation';
import { BeButtonLink } from '../forms';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import isEqual from 'lodash-es/isEqual';
import dynamic from 'next/dynamic';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import { Session } from 'next-auth';
import { calculateNavItems } from '../helpers/LayoutHelpers';
import { PageNavItem } from '@benefeature/shared-types';
import { useSideNav } from '../hooks/useSideNav';
import { DEFAULT_SCROLL_BUFFER_PX } from '../models';
import { LabelSmall } from 'baseui/typography';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { AppState, store, togglePageNav } from '../store';
import { BaseWebHelpers } from '../helpers';
import { useSelector } from 'react-redux';

// Must import dynamic with no SSR to prevent severe hydration mismatches
// Lots of user-specific stuff in the header and its submenus
const BeAppHeader = dynamic(() => import('./components/BeAppHeader').then((x) => x.BeAppHeader), {
  ssr: false,
});

// Used when on a smaller viewport with page nav
// Height should include sum of border widths as well
const BeSmallPageNavHeight = 46;

const scrollDebounceMs = 500;

export enum BackgroundImages {
  FEATURES_BACKGROUND_ACCENT = '/pages/marketing/features-background-accent.svg',
  ADMIN_TILED = "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='200px' width='240px'><text x='100' y='50' fill='%23EEEEEE' font-size='30' font-family='sans-serif'>Admin</text></svg>\")",
}

export type BeMainAppLayoutProps = Omit<AppHeaderProps, 'onSideNavClick' | 'mainNavMenu'> & {
  children: ReactNode;
  backgroundColor?: string;
  secondaryHeader?: ReactNode;
  pageNavItems?: PageNavItem[];
  lastSearchURL?: string;
  noTopBottomPad?: boolean;
  session?: Session | undefined | null;
  backgroundImg?: BackgroundImages | string;
};

export const BeMainAppLayout = memo(
  function BeMainAppLayout({
    children,
    searchComponent,
    promoBlock,
    entityContextComponent,
    userFallbackComponent,
    userMenu,
    backgroundColor,
    secondaryHeader,
    lastSearchURL,
    noTopBottomPad,
    dismissAllNotificationsFn,
    downloadExportFn,
    pageNavItems,
    session,
    backgroundImg = BackgroundImages.FEATURES_BACKGROUND_ACCENT,
  }: BeMainAppLayoutProps) {
    const [section] = useDebugAttrs(BeMainAppLayout.name);
    const [css, $theme] = useStyletron();
    const router = useRouter();
    const breakpoint = useBreakpoint();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const isPageNavOpen = useSelector(({ uiConfigReducer }: AppState) => uiConfigReducer.pageNavOpen);

    const onLogoClicked = () => {
      if (breakpoint === 'xlarge') {
        router.push(session?.user ? RouteEnum.DASHBOARDS : RouteEnum.HOME);
      } else {
        setIsMenuOpen(!isMenuOpen);
      }
    };

    /* Handling of scrolling and active items */
    const [activeItemId, setActiveItemId] = useState<string>('');
    const validSectionsAndItems = useSideNav(pageNavItems);
    const [pageNavSelectOptions, setPageNavSelectOptions] = useState([]);
    // noinspection FunctionWithMultipleLoopsJS - multiple loops expected and appropriate
    useEffect(() => {
      if (validSectionsAndItems.pageNavItemsFiltered && validSectionsAndItems.pageNavItemsFiltered.length > 0) {
        const tmpNavSelectOptions = [];
        validSectionsAndItems.pageNavItemsFiltered.forEach((item) => {
          tmpNavSelectOptions.push({ id: item.itemId, value: item.title, label: item.title, href: `#${item.itemId}` });
          if (item.subNav?.length > 0) {
            item.subNav.forEach((subNavItem) => {
              tmpNavSelectOptions.push({
                id: subNavItem.itemId,
                value: subNavItem.title,
                label: `- ${subNavItem.title}`,
                href: `#${subNavItem.itemId}`,
              });
            });
          }
        });
        setPageNavSelectOptions(tmpNavSelectOptions);
      }
    }, [validSectionsAndItems.pageNavItemsFiltered]);

    // Scroll to the fragment/anchor if it hasn't been scrolled yet
    // This could either be due to a new page load or a changed fragment/anchor
    const [scrolledToAnchor, setScrolledToAnchor] = useState<string>(null);
    const [timerID, setTimerID] = useState(null);
    const contentCallbackRef = useCallback(
      (node) => {
        if (!node) {
          return;
        }
        const resizeObserver = new ResizeObserver(() => {
          clearTimeout(timerID);

          // Must have a window location hash, not have scrolled to the anchor, and the current anchor is not the hash
          if (window.location.hash && !scrolledToAnchor && scrolledToAnchor !== window.location.hash) {
            setTimerID(
              // Allow a short debounce for further changes to the content ref size
              setTimeout(() => {
                const elem = document.getElementById(window.location.hash.slice(1));

                if (elem) {
                  elem.scrollIntoView({ behavior: 'smooth' });
                  setScrolledToAnchor(window.location.hash);
                }
              }, scrollDebounceMs)
            );
          }
        });
        resizeObserver.observe(node);
      },
      [timerID, scrolledToAnchor]
    );

    // X value is unused
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { /* x is unused */ x: _windowScrollX, y: windowScrollY } = useWindowScroll();
    useEffect(() => {
      if (windowScrollY != null && validSectionsAndItems.sections) {
        /* Page nav item handling */
        if (pageNavItems?.length) {
          if (validSectionsAndItems.sections?.length) {
            // Slice and reverse the sections (reverse is an in-place operation)
            // Allows iteration from the lowest position back through the earliest which facilitates a break once the condition is met
            let activeItemID = null;
            for (const section of validSectionsAndItems.sections.slice().reverse()) {
              /* Check if the current scroll position is near the top of the section
               * Must add in the viewport height as well */
              if (windowScrollY + DEFAULT_SCROLL_BUFFER_PX >= section['offsetTop']) {
                activeItemID = section.id;

                // Break out of the for loop, no need to continue
                // noinspection BreakStatementJS
                break;
              }
            }

            // Default the active item ID to the first section's ID (if available)
            if (!activeItemID) {
              activeItemID = validSectionsAndItems.sections?.[0]?.id;
            }

            // Set the active item state
            setActiveItemId(activeItemID ? `#${activeItemID}` : null);
          }
        }
      }
    }, [windowScrollY, validSectionsAndItems.sections, pageNavItems]);

    const [mainNavMenuState, setMainNavMenuState] = useState({ items: [] });
    useEffect(() => {
      setMainNavMenuState(calculateNavItems(session, lastSearchURL, breakpoint, router));
    }, [router, breakpoint, session, lastSearchURL]);

    const contentStyle = css({
      backgroundColor:
        backgroundImg && !backgroundColor ? 'transparent' : $theme.colors[backgroundColor] || backgroundColor,
      position: 'relative',
      minHeight: '120vh',
    });

    const contentBlockStyle = css({
      minHeight: '100vh',
      ...(noTopBottomPad
        ? {}
        : {
            paddingTop: '20px',
            paddingBottom: '40px',
          }),
    });

    const allHeadersStickyWrapperStyle = css({
      /* Add box shadow, don't use elevation since that will cast a shadow above as well */
      boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 6px -2px, rgba(15, 15, 15, 0.08) 0 10px 11px',
    });

    const primaryNavStyle = css({
      position: 'relative',
      top: '0',
      width: '100%',
    });

    const secondaryNavStyle = css({
      position: 'relative',
      width: '100%',
    });

    const pageNavSmallMediumStyle = css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: `${BeSmallPageNavHeight}px`,
      position: 'relative',
      backgroundColor: $theme.colors.backgroundPrimary,
      paddingLeft: '12px',
      width: '100%',
    });

    const contentPageNavItemsStyle = css({
      alignSelf: 'flex-start',
      backgroundColor: $theme.colors.backgroundPrimary,
      /* Can't use flex here for sticky behavior to work, leverage block */
      display: 'block',
      /* Set this to some value greater than the viewport to provide offset padding for the child sticky */
      height: '100vh',
      position: 'sticky',
      top: 0,
      bottom: 0,
      left: 0,
      /* Allow it to contract down to its width but not expand any further */
      flex: 'auto',
      flexShrink: '1',
      flexGrow: '0',
      paddingRight: isPageNavOpen ? '20px' : 0,
      paddingTop: '10vh',
      /* Bottom padding to ensure the nav items don't bump into the footer */
      paddingBottom: '80px',

      /* Toggle page nav handling */
      // Don't do a transition for this effect, lots of content will need to recalculate widths and reflow as it frames through
      // transition: 'max-width 0.2s ease-in-out, max-height 0.2s ease-in-out',
      /* Don't leverage an opacity change here */
      overflowX: 'clip',
      maxWidth: isPageNavOpen ? '600px' : 0,
    });

    const contentChildrenWrapperStyle = css({
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    });

    return (
      <Block
        {...section('root')}
        position={'relative'}
        display={'flex'}
        /* IMPORTANT: use column-reverse to ensure proper layer stacking
         * e.g. the first element will be the lowest layer, which is then covered by the next element (and layer), and so on */
        flexDirection={'column-reverse'}
      >
        <Block
          {...section('content')}
          ref={contentCallbackRef}
          id={'content-block'}
          key={'content-block'}
          className={contentStyle}
        >
          <Block
            {...section('content-block')}
            position={'relative'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gridColumnGap={0}
            className={contentBlockStyle}
          >
            {validSectionsAndItems.pageNavItemsFiltered?.length > 0 && breakpoint === 'xlarge' ? (
              <>
                <Block
                  {...section('content-pageNavItems')}
                  key={'content-pageNavItems'}
                  className={contentPageNavItemsStyle}
                  overrides={{
                    Block: {
                      props: {
                        id: 'content-pageNavItems',
                      },
                    },
                  }}
                >
                  <Navigation
                    {...section('content-pageNavItems-navigation')}
                    key={'content-pageNavItems-navigation'}
                    items={isPageNavOpen ? validSectionsAndItems.pageNavItemsFiltered : []}
                    activeItemId={activeItemId}
                    overrides={{
                      Root: {
                        style: {
                          /* Define the sticky behavior on the nav itself, NOT the parent block */
                          position: 'sticky',
                          /* Top offset is tricky, but offsetting by the viewport height divided by 3.6 ensures a workable value */
                          top: `calc(100vh / 3.6)`,
                        },
                      },
                      NavItem: {
                        style: ({ $theme, $active }) => ({
                          ...($active
                            ? {
                                borderLeftColor: $theme.colors.blue500,
                                color: $theme.colors.blue500,
                                fontWeight: '600',
                                ':hover': {
                                  color: $theme.colors.blue500,
                                },
                              }
                            : {}),
                          borderLeftWidth: '4px',
                          marginLeft: '8px',
                          width: '250px',
                        }),
                      },
                    }}
                  />
                </Block>

                {/* Expand/collapse button */}
                <Block
                  {...section('page-nav-expand-collapse')}
                  height={'100%'}
                  width={'32px'}
                  top={'calc(50% + 100px)'}
                  position={'sticky'}
                >
                  <BeButtonLink
                    endEnhancer={
                      isPageNavOpen ? (
                        <ExpandLessRoundedIcon fontSize={'inherit'} />
                      ) : (
                        <ExpandMoreRoundedIcon fontSize={'inherit'} />
                      )
                    }
                    size={'mini'}
                    kind={'tertiary'}
                    onClick={() => {
                      store.dispatch(togglePageNav(!isPageNavOpen));
                    }}
                    overrides={{
                      EndEnhancer: {
                        style: {
                          paddingBottom: 0,
                        },
                      },
                      BaseButton: {
                        style: {
                          ...BaseWebHelpers.borderRadius('8px'),
                          paddingTop: 0,

                          position: 'absolute',
                          left: isPageNavOpen ? '-8px' : 0,
                          height: '32px',
                          width: '140px',
                          transformOrigin: 'left top 0',
                          transform: 'rotate(270deg)',

                          marginLeft: 'auto',
                          marginRight: 'auto',

                          // Add some elevation to the button if the page nav is not open
                          ...(isPageNavOpen ? {} : $theme['elevations']['z2']),

                          /* Override background when active/hovered/focused */
                          ':active': {
                            backgroundColor: $theme.colors.backgroundPrimary,
                          },
                          ':hover': {
                            backgroundColor: $theme.colors.backgroundPrimary,
                          },
                          ':focus': {
                            backgroundColor: $theme.colors.backgroundPrimary,
                          },
                        },
                      },
                    }}
                  >
                    {isPageNavOpen ? <>Collapse Nav</> : <>Expand Nav</>}
                  </BeButtonLink>
                </Block>
              </>
            ) : null}

            <Block
              {...section('content-children-wrapper')}
              key={'content-children-wrapper'}
              className={contentChildrenWrapperStyle}
              overrides={{
                Block: {
                  props: {
                    id: 'content-children-wrapper',
                  },
                },
              }}
            >
              {children}
            </Block>
          </Block>

          {/* Render the footer within the content block to ensure it shows up under the appropriate layer */}
          <BeAppFooter />
        </Block>

        <Block
          {...section('all-headers-sticky-wrapper')}
          position={'sticky'}
          top={0}
          className={allHeadersStickyWrapperStyle}
        >
          {/* IMPORTANT: render all headers after content to ensure they're painted on top (layer order is correct without adjustment) */}
          <Block {...section('primary-nav-app-header-wrapper')} className={primaryNavStyle}>
            <BeAppHeader
              userMenu={userMenu}
              entityContextComponent={entityContextComponent}
              searchComponent={searchComponent}
              promoBlock={promoBlock}
              userFallbackComponent={userFallbackComponent}
              mainNavMenu={mainNavMenuState}
              onLogoClicked={onLogoClicked}
              dismissAllNotificationsFn={dismissAllNotificationsFn}
              downloadExportFn={downloadExportFn}
            />
          </Block>

          {secondaryHeader && (
            <Block className={secondaryNavStyle} {...section('secondary-nav')}>
              {secondaryHeader}
            </Block>
          )}

          {validSectionsAndItems.pageNavItemsFiltered?.length > 0 && breakpoint !== 'xlarge' ? (
            <Block className={pageNavSmallMediumStyle} {...section('page-nav-section')}>
              <LabelSmall>Navigate:</LabelSmall>

              <StatefulPopover
                returnFocus
                dismissOnClickOutside
                placement={PLACEMENT.bottomLeft}
                popoverMargin={0}
                popperOptions={{ modifiers: { flip: { enabled: false } } }}
                triggerType={TRIGGER_TYPE.click}
                overrides={{
                  Inner: { style: ({ $theme }) => ({ backgroundColor: $theme.colors.white }) },
                }}
                content={({ close }) => {
                  return (
                    <StatefulMenu
                      items={pageNavSelectOptions}
                      onItemSelect={({ item }) => {
                        router.push(item.id as string, null, { shallow: true }).then(() => {
                          setActiveItemId(item.id as string);
                        });
                        close();
                      }}
                      overrides={{
                        List: { style: { ':focus': { outline: 'none' } } },
                        ListItem: { style: { fontSize: '16px' } },
                      }}
                    />
                  );
                }}
              >
                {/* Use a block as a target for the stateful popover */}
                <Block>
                  <BeButtonLink
                    kind={'tertiary'}
                    size={'default'}
                    overrides={{
                      EndEnhancer: {
                        style: {
                          marginLeft: 0,
                        },
                      },
                      BaseButton: {
                        style: ({ $theme }) => ({
                          paddingRight: '8px',
                          color: $theme.colors.contentSecondary,
                        }),
                      },
                    }}
                    endEnhancer={<ExpandMoreRoundedIcon />}
                  >
                    {pageNavSelectOptions?.find((item) => item?.id === activeItemId)?.label}
                  </BeButtonLink>
                </Block>
              </StatefulPopover>
            </Block>
          ) : null}
        </Block>

        {/* Apply the default background if requested
         * This can be nearly the last element in the reverse order (near the top of the layer pile)
         * Explicit z-index pushes it down */}
        {backgroundImg ? (
          <Block
            position={'fixed'}
            top={0}
            left={0}
            right={0}
            height={'100vh'}
            backgroundImage={
              backgroundImg?.startsWith('url(')
                ? backgroundImg
                : `url("${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}${backgroundImg}")`
            }
            {...(backgroundImg?.startsWith('url(')
              ? {}
              : {
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                })}
            overrides={{ Block: { style: { zIndex: -1 } } }}
          />
        ) : null}

        {isMenuOpen && (
          <Layer>
            <Drawer renderAll anchor={ANCHOR.left} onClose={() => setIsMenuOpen(false)} isOpen={isMenuOpen}>
              <BeSideNav isCollapsed={false} {...mainNavMenuState} />
            </Drawer>
          </Layer>
        )}
      </Block>
    );
  },
  (a, b) => isEqual(a, b)
);
