import { Client, Server } from 'styletron-engine-atomic';

const getHydrateClass = () => document.getElementsByClassName('_styletron_hydrate_');

const styletronClassPrefix = 'bfr--';

export const styletron =
  /* Prefix EVERY class to prevent conflicts with plugins/extensions/other code bases (BFR-1307)
   * Do this for both the Server and Client implementations to prevent hydration mismatches */
  typeof window === 'undefined'
    ? new Server({
        prefix: styletronClassPrefix,
      })
    : new Client({
        hydrate: getHydrateClass(),
        prefix: styletronClassPrefix,
      });
